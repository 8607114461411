import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { DarkTheme } from '../../App';
import FiltersMenuIconButton from '../../Components/FormAndFilters/FiltersMenuIconButton';
import Pagination from '../../Components/PaginationComponentHard';
import SetupColumns from '../../Components/SetupColums/SetupColumns';
import ModalConfirm from '../../Components/Various/ModalConfirm';
import '../../Css/AziendaList.css';
import '../../Css/Index.css';
import { useOperationTableCrud } from '../../Hook/Grid/useOperationTableCrud';
import { useResourceTableCrud } from '../../Hook/Grid/useResourceTableCrud';
import { DataModalConfirm, stateConfirm } from '../../Model/CommonModel';
import { MovimentazioneViewModel, MovimentazioneViewModelQuery } from '../../Model/MovimentazioneModel';
import setLoader from '../../Redux/Action/LoaderAction';
import { GetMovimentazioneCreateResource } from '../../Redux/Action/Movimentazione/MovimentazioneCreateAction';
import { deleteMovimentazione } from '../../Redux/Action/Movimentazione/MovimentazioneDeleteAction';
import { GetMovimentazioneReport } from '../../Redux/Action/Movimentazione/MovimentazioneDownloadAction';
import { GetAddResultToGrid, GetFilterColumnMovimentazioneViewModel, GetMovimentazioneViewModel } from '../../Redux/Action/Movimentazione/MovimentazioneGridAction';
import { RootState } from '../../Redux/Store/rootStore';
import MovimentazioneGrid from '../Movimentazione/MovimentazioneGrid';
import MovimentazioneModal from '../Movimentazione/MovimentazioneModal';
import 'bootstrap/dist/css/bootstrap.min.css';

interface Props {
  isOnline: boolean
}

const MovimentazioneContainer: React.FC<Props> = (props) => {

  const darkTheme = useContext(DarkTheme)

  let initialState: MovimentazioneViewModelQuery = {

    sortBy: "",
    isSortAscending: false,
    page: 1,
    pageSize: 10,
    azienda: [],
    aziendaSeller:[],
    operationText:[],
    total:null,
    pagato:[],
    numeroFattura: null,
    movimentazioneProductVariant:[]
  }

  useEffect(() => {
    if (props.isOnline === false)
      setQuery(query, true)
  }, [props.isOnline])

  //UTILITY
  const [confirmCustom, setConfirmCustom] = useState<DataModalConfirm>(stateConfirm)

  //REDUCERS
  const GridData = (state: RootState) => state.MovimentazioneGridReducer.gridResult;
  let GridDto = useSelector(GridData);

  const LayoutData = (state: RootState) => state.layoutReducer;
  let layout = useSelector(LayoutData);

  const downloadedData = (state: RootState) => state.MovimentazioneDownloadReducer.dtoDownload;
  let downloadedList = useSelector(downloadedData);

  //DETAIL
  const [isDetailOpened, setIsDetailOpened] = useState<boolean>(false)
  const [isVisibleModalSetup, setIsVisibleModalSetup] = useState<boolean>(false);




  //FILTERS
  const { query, setQuery, next, back, goToPage } = useResourceTableCrud(initialState, GetMovimentazioneViewModel, layout.isMobile ?? true, GetAddResultToGrid, GridDto?.items);
  const onDelete = async () => {
    await GetMovimentazioneViewModel(initialState).then(() => {
      setLoader("REMOVE", "GetMovimentazioneViewModel")
    });
  }
  const { New, Edit, isVisibleModal, edit, confirm, setConfirm, closeModal, Delete, localStateHistory, setLocalState } = useOperationTableCrud<MovimentazioneViewModel, MovimentazioneViewModel>(
    GetMovimentazioneCreateResource,
    GetMovimentazioneCreateResource,
    deleteMovimentazione,
    onDelete
  );

  const closeModalSetup = async (changed: boolean) => {
    setIsVisibleModalSetup(false)
    await GetMovimentazioneViewModel(query ?? initialState).then(() => {
      setLoader("REMOVE", "GetMovimentazioneViewModel")
    });
  };

  const closeModalForm = async (changed: boolean) => {
     closeModal(changed);
    setIsVisibleModalSetup(false)

    await GetMovimentazioneViewModel(query ?? initialState).then(() => {
      setLoader("REMOVE", "GetMovimentazioneViewModel")
    });
  };

  const InvocheDownload = async () => {
		let result = await GetMovimentazioneReport(query);
		if (result !== undefined) {
			let url = window.URL.createObjectURL(result.file);
			let a = document.createElement("a");
			a.href = url;
			a.download = result.fileName;
			a.click();
		}
	};
  return (
    <div className={`mt-3 row mx-0 relative h-100 pageContainer justify-content-center ${darkTheme ? "dark" : ""}`}>
      <ModalConfirm data={confirm} />

      <Modal show={isVisibleModal} onHide={closeModal} backdrop="static" keyboard={false} fullscreen>
        <Modal.Header className="d-flex justify-content-center">
          <div className="col-12 px-0 mb-2">
            <div className="col-12 mt-3">
              <h4>{edit ? "Modifica movimentazione" : "Aggiungi Movimentazione"}</h4>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <MovimentazioneModal action={{ closeModal: closeModalForm }} edit={edit} isVisibleModal={isVisibleModal} />
        </Modal.Body>
      </Modal>

      <div className="d-flex align-items-center justify-content-between w-100 ml-1">
        <h2 className="mb-0">Lista Movimentazioni</h2>
        <button className="text-uppercase voda-bold btn btn-danger px-4 btnHeader" type="button" onClick={() => InvocheDownload()}>
						<img alt="Download" width="26" style={{ marginBottom: "2px" }} src={require("../../img/excel.png")} />
					</button>
        <button className='btn btnOutline' type='button' onClick={() => New()}>Aggiungi Movimentazione</button>
      </div>
      <div className='  pt-3'>
        <div className={` pb-5 pt-4 ${layout.isMobile ? "" : "tableContainer"}`}>
          {layout.isMobile ?
            <div className="d-flex align-items-center justify-content-between ">
              <FiltersMenuIconButton containerClass={""} initialStateQuery={initialState} query={query} propertyToNotOverride={"projectCode"} action={{ applyFilters: setQuery, getFilterColumn: GetFilterColumnMovimentazioneViewModel }} renderGrid={GridDto?.gridRender?.render} />
            </div>
            :
            <div className='  pb-2 d-flex justify-content-end relative'>
              <button type='button' className='btn px-0 btnColonne d-flex align-items-center justify-content-center' onClick={() => setIsVisibleModalSetup(!isVisibleModalSetup)}>
                <img alt="colonne" src={require('../../svg/colonne.png')}></img>
                <span className='ml-2 mt-1'>Modifica Colonne</span>
              </button>
              {isVisibleModalSetup &&
                <div className="pr-5 absolute" style={{ top: "102%", right: "0", minWidth: "500px" }}>
                  <div className="orderContainer absolute">
                    <SetupColumns renderGrid={GridDto?.gridRender} action={{ closeModalSetup, setIsVisibleModalSetup }}></SetupColumns>
                  </div>
                </div>
              }
            </div>}
            <div className='col-12 h-100 paginationScrollable' id='paginationScrollable'>
            <div id="scrollableDiv" style={{ height: layout.isMobile ? 550 : "100%", overflow: "auto" }}>
              <Pagination isMobile={layout.isMobile ?? false} pagination={{ page: query.page, pageSize: query.pageSize }} totalItems={GridDto?.totalItems ?? 0} itemsLength={GridDto?.items?.length ?? 0} actions={{ next, back, goToPage }} >
              <MovimentazioneGrid action={{ setConfirm, setQuery, Edit, onDelete: Delete }} isMobile={layout.isMobile ?? false}  data={GridDto?.items} downloadedList={downloadedList} query={query} renderGrid={GridDto?.gridRender?.render} />
              </Pagination>
            </div></div>
        </div>
      </div>
    </div>
          
   );
}
export default MovimentazioneContainer


 

